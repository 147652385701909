import React from 'react';
import styled from 'styled-components';
import FooterBlurb from './footer/FooterBlurb';
import FooterContact from './footer/FooterContact';
import FooterNav from './footer/FooterNav';
import FooterEmail from './footer/FooterEmail';

const Container = styled.footer`
    color: #fff;
    padding: 5px;

    a {
        color: #fff;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const Copyright = styled.div`
    display: table;
    margin: 0 auto;
`;

const FooterMain = styled.div`
    display: flex;
    flex-direction: row;
`;

const Footer = () => (
    <Container>
        <div className="empty-space marg-lg-b60 marg-sm-b60"></div>
        <div className="container">
            <div className="row" style={{ padding: '15px 0'}}>
                <aside className="col-md-9 col-sm-6">
                    <h4>How can I help you?</h4>
                </aside>

                <aside className="col-md-3 col-sm-6" style={{ textAlign: 'right' }}>
                    <a className="btn btn-primary" href="/contact">Let's Talk</a>
                </aside>
            </div>
        </div>
        <div className="empty-space marg-lg-b60 marg-sm-b60"></div>

        <hr />

        <div className="empty-space marg-lg-b60 marg-sm-b60"></div>
        <div className="container">
            <FooterMain className="row">
                <FooterBlurb />
                <FooterContact />
                <FooterNav />
                <FooterEmail />
            </FooterMain>
        </div>
        <div className="empty-space marg-lg-b60 marg-sm-b60"></div>

        <hr />

        <Copyright>
            &copy; {new Date().getFullYear()} Secure Web Solutions LLC
        </Copyright>
    </Container>
);

export default Footer;
