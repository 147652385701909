import React from 'react';
import styled from 'styled-components';

const Nav = styled.ul`
    list-style: none;
`;

const FooterNav = () => (
    <section className="col-md-3 col-sm-6">
        <Nav>
            <li><a href="/">Home</a></li>
            <li><a href="https://www.youtube.com/playlist?list=PLQb4kK5hIqZTsGS3Qlq-5cwozoiLOpK_W" target="_blank">Online Course</a></li>
            <li><a href="/strategic-software">Newsletter</a></li>
            <li><a href="/contact">Contact</a></li>
        </Nav>
    </section>
);

export default FooterNav;