import React from 'react';
import styled from 'styled-components';

const Container = styled.header`
    color: #fff;

    font-weight: 900;

    a {
        color: #fff;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const Title = styled.h2``;

interface HeaderProps {
    title: string;
    isHome?: boolean;
}

const Header = ({ title, isHome }: HeaderProps) => (
    <Container>
        <nav className="navbar navbar-expand-lg navbar-dark">
            <button
                className="navbar-toggler float-xs-right" 
                type="button" 
                data-toggle="collapse" 
                data-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                <li className="nav-item">
                        <a className="nav-link" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://www.youtube.com/playlist?list=PLQb4kK5hIqZTsGS3Qlq-5cwozoiLOpK_W" target="_blank">Online Course</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/strategic-software">Newsletter</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact</a>
                    </li>
                </ul>
            </div>
        </nav>
    </Container>
);

export default Header;