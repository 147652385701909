import React from "react";
import styled from 'styled-components';

import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';

const WRAPPER = styled.div`
  height: 100%;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;

  display: flex;
  flex-direction: column;
`;

const BACKGROUND = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(#03447d 200px, #000000);
`;

const CONTAINER = styled.div`
  flex: 1;
  margin: 0 auto;
  max-width: 75%;
  padding: 15px;
`;

export const SECTION = styled.div`
  margin-top: 50px;
`;

export const ROW = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
`;

export const HEADING = styled.h2`
  font-size: 30pt;
  font-weight: 700;
  color: #fff;
`;

export const TAGLINE = styled.p`
  font-size: 16pt;
  font-weight: 400;
  color: #fff;
`;

export const CONTENT = styled.p`
  font-size: 16pt;
  font-weight: 400;
  color: #fff;
`;

export const LABEL = styled.label`
  font-size: 16pt;
  font-weight: 400;
  color: #fff;
`;


export const CONTENT_LI = styled.li`
  font-size: 16pt;
  font-weight: 400;
  color: #fff;
`;

export const TEXT = styled.span`
  color: #40b1e8;
  font-weight: 700;
`;

const Page = ({ children }) => {

  return (
    <WRAPPER>
      <style>{`
        html, body {
          margin: 0;
          padding: 0;
          overflow: hidden;
          font-family: Barlow, sans-serif;
          background-color: #03447d;
        }
      `}</style>
      <BACKGROUND />

      <CONTAINER className="container">
        <Header title={"Home"} />

        <img
          src="/img/securewebsolutions-light.jpeg"
          alt="Secure Web Solutions LLC, McHenry IL Software Company" 
          style={{ width: '260px', marginLeft: '-20px' }} />

        <SECTION>
          {children}
        </SECTION>

        <Footer />
      </CONTAINER>
    </WRAPPER>
  );
};

export default Page;
