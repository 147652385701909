import React, { useState } from 'react';

const FooterEmail = () => (
    <section className="col-md-3 col-sm-6">
        <h6>Stay in Touch:</h6>

        <form
            action="https://akawebdesign.us1.list-manage.com/subscribe/post?u=146b64a4b90a55f1a937221f2&amp;id=f467fdb108"
            method="post"
            className="form-inline"
            target="_blank"
        >
            <div className="input-group">
                <input
                    type="text"
                    name="EMAIL"
                    placeholder="Enter your email..."
                    className="form-control"
                />
                <input
                    type="hidden"
                    name="EMAILTYPE"
                    value="html"
                />
                <button type="submit" className="btn btn-primary">
                    <i className="fa fa-paper-plane"></i>
                </button>
            </div>
        </form>
    </section>
);

export default FooterEmail;
