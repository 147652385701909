import React from 'react';
import styled from 'styled-components';

const Nav = styled.ul`
    list-style: none;
`;

const FooterContact = () => (
    <section className="col-md-3 col-sm-6">
        <Nav>
            <li><a href="https://github.com/arthurakay" target="_blank"><i className="fab fa-github-square"></i> GitHub</a></li>
            <li><a href="https://stackoverflow.com/users/551436/arthurakay" target="_blank"><i className="fab fa-stack-overflow"></i> StackOverflow</a></li>
            <li><a href="https://twitter.com/arthurakay" target="_blank"><i className="fab fa-twitter"></i> Twitter</a></li>
            <li><a href="https://www.linkedin.com/in/arthurakay" target="_blank"><i className="fab fa-linkedin"></i> LinkedIn</a></li>
            <li><a href="https://www.youtube.com/user/arthurakay" target="_blank"><i className="fab fa-youtube-square"></i> YouTube</a></li>
            <li><a href="https://www.facebook.com/aKa-Web-Design-123784897644176" target="_blank"><i className="fab fa-facebook-square"></i> Facebook</a></li>
        </Nav>
    </section>
);

export default FooterContact;