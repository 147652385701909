import React from 'react';

const FooterBlurb = () => (
    <section className="col-md-3 col-sm-6">
        <p>
            Secure Web Solutions LLC is a freelance consultancy focused on delivering innovative, 
            secure, high-quality software solutions for affordable prices.
        </p>
    </section>
);

export default FooterBlurb;